import { gql } from "graphql-request";

export const ACADEMY_INFORMATION = gql`
    query {
        Academy (id: 1) {
            id
            name
            name_ar
            sub_fees
            descr
            descr_ar
        }
    }
`
export const ACADEMY_IMAGES = gql`
    query {
        AcademyImages {
            id
            path
        }
    }
`
