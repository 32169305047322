<template>
  <div>
    <va-card>
      <va-card-title>{{ $t('academy_information') }}</va-card-title>
      <va-card-content>
        <!-- <div class="row">
                    <div class="md6"> -->
        <va-breadcrumbs color="primary">
          <va-breadcrumbs-item :label="$t('menu.main')" />
          <va-breadcrumbs-item :label="$t('menu.academy')" />
          <va-breadcrumbs-item :label="$t('menu.information')"/>
        </va-breadcrumbs>
        <!-- </div>
                    <div class="md6">
                        <va-button icon-right="create" class="mr-4">Edit</va-button>
                    </div> -->
        <!-- </div> -->
      </va-card-content>
    </va-card>
            
       
    <div class="mt5">
      <div class="flex">
        <va-card stripe stripe-color="primary">
          <va-card-title></va-card-title>
          <va-card-content>


            <div class="mx-3">
              <h1>Academy Images</h1>
            </div>
            <div  class="row mb-3 mx-3">
              <academy-images></academy-images>
            </div>

            <va-divider inset />


            <div class="avatar-container">
              <va-avatar size="7rem" font-size="30px" class="">S</va-avatar>
            </div>
            <div class="mt5">
              <va-inner-loading :loading="isLoading">
              
                <form @submit.prevent="formSubmit">
                  <div class="md6">
                    <va-input
                      class="mb-4 mx-3"
                      v-model="data.name"
                      :label="$t('name')"
                    />
                                  
                    <va-input
                      class="mb-4 mx-3"
                      v-model="data.name_ar"
                      :label="$t('name_ar')"
                    />
                  </div>
                  <div class="md6">
                    <va-input
                      class="mb-4 mx-3"
                      v-model="data.descr"
                      type="textarea"
                      :label="$t('descr')"
                    />
                                  
                    <va-input
                      class="mb-4 mx-3"
                      v-model="data.descr_ar"
                      type="textarea"
                      :label="$t('descr_ar')"
                    />
                  </div>
                  <va-input
                    class="mb-4 mx-3"
                    v-model.number="data.sub_fees"
                    type="number"
                    :label="$t('subscription_fees')"
                  />

                  <va-divider inset />
                                  
                  <div class="card_footer">
                    <va-button outline class="mr-4" type="submit">{{$t('save')}}</va-button>
                  </div>

                </form>
              </va-inner-loading>

            </div>
          </va-card-content>
                    
        </va-card>
      </div>
    </div>
  </div>
</template>

<script>
import { request, GraphQLClient } from 'graphql-request'
import { ACADEMY_UPDATE_MUTATION } from "@/graphql/mutations/academy/information.js";
import { ACADEMY_INFORMATION } from "@/graphql/queries/academy/information.js";
import AcademyImages from "@/components/academy/information/AcademyImages.vue"
import store from '@/store';

export default {
  components: {
    AcademyImages
  },
  data () {
    return {
      data: {},
      isLoading: false
    }
  },
  methods: {
    async formSubmit () {
      this.isLoading = true
      const { id, name, name_ar, descr, descr_ar, sub_fees } = this.data;
        
        console.log(this.$store.state.appUrl);
        const graphQLClient = new GraphQLClient(this.$store.state.appUrl, {
          headers: {
            // authorization: 'Bearer MY_TOKEN',
          },
        })


        const variables = {
          id, name, name_ar, descr, descr_ar, sub_fees
        };
        try{
        const data = await graphQLClient.request(ACADEMY_UPDATE_MUTATION, variables)
        this.isLoading = false;
        console.log(JSON.stringify(data, undefined, 2))
        }catch(err){
            this.$swal.fire({
            text:this.$t('error_msg'),
            icon:'error',
            confirmButtonColor: '#039303',
            confirmButtonText: this.$t('ok'),
          })
        }
    },
  },
  created() {
    request(this.$store.state.appUrl, ACADEMY_INFORMATION).then((data) => {
      console.log(data)
      this.data = data.Academy[0];
    }).catch((error) => {
      console.log(error);
      this.$swal.fire({
          text:this.$t('error_msg'),
          icon:'error',
          confirmButtonColor: '#039303',
          confirmButtonText: this.$t('ok'),
      })
    });
  }
}
</script>

<style lang="scss" scoped>
    .mt5{
        margin-top: 15px;
    }
    .md6{
        display: flex;
        align-items: center;
    }
    .avatar-container{
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
    .card_footer{
        display: flex;
        justify-content: flex-end;
    }
</style>