import { gql } from "graphql-request";

export const ACADEMY_UPDATE_MUTATION = gql`
    mutation updateAcademyMutation($id: Int, $name: String, $name_ar: String, $descr: String, $descr_ar: String, $sub_fees: Int){
        AcademyUpdate(academy: {id: $id, name: $name, name_ar: $name_ar, descr: $descr, descr_ar: $descr_ar, sub_fees: $sub_fees} ){
            name,
            name_ar,
            descr,
            descr_ar,
            sub_fees
        }
    }
`
export const INSERT_ACADEMY_IMG = gql`
    mutation ($path: Upload){
        insertAcademyImage(insertAcademyImageInput: {path: $path}){
        status
        msg
        }
    }
`
export const DELETE_ACADEMY_IMG = gql`
    mutation ($id: Int!){
        deleteAcademyImage(deleteAcademyImageInput: {id:$id}){
            status
        }
    }
`
