<template>
        
  <div v-for="(img, i) in images" :key="i" class="flex md4 lg2">
    <div style="text-align: center;">
      <va-image :src="imgUrl + '/' + img.path" />
      <va-icon
        name="delete"
        color="#ff0000"
        size="medium"
        class="mr-2"
        style="cursor:pointer;"
        @click="removeAcademyImage(img.id)"
      />
    </div>
  </div>
  <div @click="$refs.img_file.click()" style="cursor: pointer;text-align: center;display: flex;align-items: center;background: #ccc;" class="flex md4 lg2 bg-secondary">
    <div>
      <va-icon
        name="add"
        size="4rem"
        class="mr-2"
      />
      <input @change="insertNewAcademyImg()" ref="img_file" type="file" style="display:none"/>
    </div>
  </div>
</template>

<script>

import { request, GraphQLClient } from 'graphql-request'
import { ACADEMY_IMAGES } from '@/graphql/queries/academy/information'
import { INSERT_ACADEMY_IMG, DELETE_ACADEMY_IMG } from '@/graphql/mutations/academy/information'

export default {
    data(){
        return {
            images: [],
        }
    },
    computed: {
        imgUrl(){
            return this.$store.getters.getUrl
        }
    },
    methods: {
        getAcademyImages(){
            request(this.$store.state.appUrl, ACADEMY_IMAGES).then(
            (data) => {
                console.log(data)
                this.images = data.AcademyImages
            }).catch((err)=> {
                console.log(err);
                // this.isLoading = false;
            })
        },
        async insertNewAcademyImg(){
            console.log("sd");

            const graphQLClient = new GraphQLClient(this.$store.state.appUrl, {})

            const variables = {
                path: this.$refs.img_file.files[0],
            }
            
            try{
                const data = await graphQLClient.request(INSERT_ACADEMY_IMG, variables)
    
                console.log(JSON.stringify(data, undefined, 2))

                this.$refs.img_file.value = null,

                this.getAcademyImages();
            }catch(err){
                console.log(err);
                this.$swal.fire({
                    text:this.$t('error_msg'),
                    icon:'error',
                    confirmButtonColor: '#039303',
                    confirmButtonText: this.$t('ok'),
                })
            }
        },
        removeAcademyImage(id){
            console.log(id);

            this.$swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const graphQLClient = new GraphQLClient(this.$store.state.appUrl, {})

                    const variables = {
                        id
                    }
                    try{
                        const data = await graphQLClient.request(DELETE_ACADEMY_IMG, variables)
            
                        console.log(JSON.stringify(data, undefined, 2))

                        this.getAcademyImages();
                        this.$swal.fire(
                        'Deleted!',
                        'Your file has been deleted.',
                        'success'
                        )
                    }catch(err){
                        console.log(err);
                        this.$swal.fire({
                            text:this.$t('error_msg'),
                            icon:'error',
                            confirmButtonColor: '#039303',
                            confirmButtonText: this.$t('ok'),
                        })
                    }
                    
                }
            })

            
        }
    },
    mounted (){
        this.getAcademyImages();
    }
}
</script>

<style>

</style>